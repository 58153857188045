import { FontDto } from '../interfaces';

export const fontWeightStyleToVariant = (weight: string, style: string) => {
  if (weight === '700') {
    if (style === 'italic') {
      return '700italic';
    } else {
      return '700';
    }
  } else {
    if (style === 'normal') {
      return 'regular';
    } else {
      return 'italic';
    }
  }
};

export const processTextForSkiaMeasurer = (text: string) =>
  text.replace(new RegExp(/\r\n/g, 'g'), '\n').replace(new RegExp(/\n/, 'g'), '\r').replace(/"/, '\\"');

export const isItalicSupportedForFont = (font: FontDto) => {
  return font.variants.find((variant) => variant.slug === 'italic');
}

export const isBoldSupportedForFont = (font: FontDto) => {
  return font.variants.find((variant) => variant.slug === '700');
}
