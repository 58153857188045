import { HostableUserPermission } from '../acl/hostable-user.permission.interface';
import { HostableUserObjectRole } from '../interfaces/entity-role.interface';

import { OverlayPosition, OverlaySize } from './video-player/player/overlay-plugin/overlay-position.interface';
import {
  HostableTypes,
  HostablePermissionTypes,
  HostableVideoStatuses,
  HostableCtaType,
  CommentableType,
  CommentableSortOptions,
  CommentAccessibility,
} from './constants';
import { KtdGridLayout } from '@katoid/angular-grid-layout';
import {
  ApprovalStatuses,
  DateInterval,
  DrawableAreaData,
  HostingAudioExtractionStatus,
  HostingCaptionsTranslationStatus,
  UserCommentPermissions,
  UserUploadOrigin,
} from '@openreel/common';

export const EVT_HOSTING_RENDER_PROGRESS = 'hosting-render-progress';
export const EVT_HOSTING_JOIN_BY_PROJECT_ID = 'join-hosting-via-project-id';
export const EVT_HOSTING_CHAPTER_RENDER_PROGRESS = 'hosting-chapter-render-progress';
export const EVT_HOSTING_VIDEO_SUMMARY_TRANSCRIPT_PROGRESS = 'hosting-video-summary-transcript-progress';
export const EVT_HOSTING_VIDEO_CAPTIONS_TRANSCRIPT_PROGRESS = 'hosting-video-captions-transcript-progress';
export const EVT_HOSTING_CAPTIONS_TRANSLATION_PROGRESS = 'hosting-captions-translation-progress';
export const EVT_HOSTING_AUDIO_EXTRACTION_PROGRESS = 'hosting-audio-extraction-progress';
export const EVT_HOSTING_VIDEO_UPDATED = 'hosting-video-updated';

export interface HostingRenderProgress {
  state: HostableVideoStatuses;
  progress: number;
  id: number;
}

export interface HostedVideoApprovalRequest {
  id: number;
  status: ApprovalStatuses;
}

export class HostableVideo {
  id: number;
  title: string;
  assetURL: string;
  slug: string;
  idHash: string;
  state: HostableVideoStatuses;
  permission: HostablePermissionTypes;
  shareUrl: string;
  createdAt: string;
  createdBy: number;
  sourceType: HostableTypes;
  sourceId: number;
  thumbnailSignedUrl: string[];
  duration: number;
  description?: string;
  altText?: string;
  hasCustomThumbnail: boolean;
  width: number;
  height: number;
  userPermissions: HostableUserPermission[];
  userObjectRole: HostableUserObjectRole;
  userDetails?: {
    account_id: number;
    fullname: string;
    email: string;
    id: number;
    thumb_image?: string;
  };
  shareable?: boolean;
  approvalRequest?: HostedVideoApprovalRequest;
  commentsDisabled?: boolean;
  commentsToken?: string;
  audio: {
    state: HostingAudioExtractionStatus;
  };
  sourceAssetId?: number;
}

export class HostableVideoEmbedApiResp {
  embedEnabledAt: Date;
  embedExceed: boolean;
  embedLimit: number;
  embedVideoCount: number;
}

export class HostableVideoEmbed extends HostableVideoEmbedApiResp {
  showUpdateBar: boolean;
}

export interface HostingVideoViewsCounter {
  playback: number;
}

export interface HostingTag {
  id: number;
  tag: string;
}

export interface HostingHub {
  id: number;
  title: string;
  permission: HostablePermissionTypes;
  slug: string;
  idHash: string;
  userPermissions: HostableUserPermission[];
  userObjectRole: HostableUserObjectRole;
  shareUrl?: string;
  isOwner?: boolean;
}

export interface VideoPlaybackData {
  id: number;
  idHash: string;
  title: string;
  accountIdHash: string;
  srcUrl: string;
  thumbUrl: string;
  captions: HostingCaption[];
  chapters: HostingVideoChapter[];
  cta: HostablePlaybackCtaDefinition[];
  brandKit: CtaBrandKit;
  appearance: HostingVideoAppearance;
}

export interface HostingParentObject {
  id: number;
  type: HostableTypes;
}

export interface UpdateVideoDetails {
  title?: string;
  description?: string;
  permission?: HostablePermissionTypes;
  altText?: string;
  hasPassword?: boolean;
  password?: string;
  slug?: string;
  shareUrl?: string;
  commentsDisabled?: boolean;
}

export interface UpdateHubDetails {
  title?: string;
  permission?: HostablePermissionTypes;
  password?: string;
  slug?: string;
}

export interface SortOption {
  field: string;
  direction: 'ASC' | 'DESC';
}

export interface PageOptions {
  pageIndex: number;
  pageSize: number;
}

export interface CreateHostingCaption {
  assetId: number;
  languageCode: string;
}

export interface UpdateHostingCaption {
  languageCode?: string;
  content?: string;
}

export interface HostingCaption extends CreateHostingCaption {
  readUrl: string;
  createdAt: Date;
  updatedAt: Date;
  id: number;
  languageDisplay: string;
  autogenerated?: boolean;
}

export interface CreateHostingThumbail {
  assetId: number;
}

export interface HostingThumbnail {
  assetId: number;
  id: number;
  readUrl: string;
  createdAt: Date;
  updatedAt: Date;
  isCustom: boolean;
}

export interface HostableCountFilters {
  sourceType?: HostableTypes;
  loggedInUserRoleId?: number;
  sharedWithCurrentTeam?: boolean;
}

export interface HostableListFilters extends HostableCountFilters, HostableFilters {
  title?: string;
  permission?: HostablePermissionTypes;
  captureProjectId?: number;
}

export interface HubVideoListFilters extends HostableFilters {
  videoState?: HostableVideoStatuses;
}

interface BaseCtaDefinition {
  type: HostableCtaType;
  timeFrame: {
    start: number;
    end: number;
  };
}

export interface TextButtonCtaDefinition extends BaseCtaDefinition {
  type: HostableCtaType.TextButton;
  buttonText: string;
  linkUrl: string;
  position: OverlayPosition;
  size?: OverlaySize;
}

interface ImageButtonCtaDefinition extends BaseCtaDefinition {
  type: HostableCtaType.ImageButton;
  imageAsset: string;
  linkUrl: string;
  position: OverlayPosition;
}

export type HostingCtaDefinition = TextButtonCtaDefinition | ImageButtonCtaDefinition;
export type HostablePlaybackCtaDefinition = HostingCtaDefinition & { id: HostingCta['id'] };

export interface CreateHostingCta {
  ctaDefinition: HostingCtaDefinition;
}

export interface UpdateHostingCta {
  ctaDefinition: HostingCtaDefinition;
}

export interface HostingCta extends CreateHostingCta {
  id: number;
  updatedAt: Date;
  createdAt: Date;
}

export interface HostingCreateData {
  id?: number;
  title: string;
  slug?: string;
  permission: HostablePermissionTypes;
  description?: string;
  altText?: string;
  trimStart?: number;
  trimEnd?: number;
}

export interface HostingEmbedDto {
  embedLimit: number;
  embedExceed: boolean;
  embedVideoCount: number;
}

export interface HostingPlayerActions {
  play: () => Promise<void>;
  pause: () => unknown;
  currentTime: (timeSeconds: number) => unknown;
}

export interface HostingPlayerVideoEvent {
  action: string;
  url: string;
  position: number;
  cta_button_url?: string | null;
}

export interface HostingVideoChapter {
  id: number;
  name: string;
  start: number;
  autogenerated: boolean;
}

export enum HostingVideoTranscriptStatus {
  None = 'none',
  Processing = 'processing',
  Failed = 'failed',
  Success = 'success',
}

export interface HostingVideoTranscript {
  jobStatus: HostingVideoTranscriptStatus;
  chapters?: HostingVideoTranscriptChapters[];
  summary?: string;
}

export interface HostingVideoTranscriptProgress {
  state: HostingVideoTranscriptStatus;
  id: number;
}

export interface HostingCaptionsTranslation {
  jobStatus: HostingCaptionsTranslationStatus;
}

export interface HostingCaptionsTranslationProgress {
  state: HostingCaptionsTranslationStatus;
  id: number;
  accountId: number;
}

export interface HostingAudioExtractionProgress {
  status: HostingAudioExtractionStatus;
  id: number;
  accountId: number;
  error: string | null;
}

export interface HostingVideoUpdatedSocketEvent {
  id: number;
  accountId: number;
}

export interface HostingVideoTranscriptChapters {
  summary: string;
  gist: string;
  headline: string;
  start: number;
  end: number;
}

export interface CtaFormModel {
  id: number | null;
  buttonText: string;
  linkUrl: string;
  position: OverlayPosition;
  timeFrame: {
    start: number;
    end: number;
  };
  size?: OverlaySize;
}

export interface CtaBrandKit {
  font: string;
  color: string;
}

export type UpdateHostingVideoChapterDTO = Omit<HostingVideoChapter, 'autogenerated'>;
export type CreateHostingVideoChapterDTO = Omit<UpdateHostingVideoChapterDTO, 'id'>;

export type UrlType = 'hub' | 'video';

export interface ApiPagedResponse<T> {
  count: number;
  rows: Array<T>;
}

export type LayoutableType = 'video' | 'hub';

export enum WidgetName {
  VideoPlayer = 'Video Player',
  Chapters = 'Video Chapters',
  Comments = 'Latest Comments',
  ContactUs = 'Contact Us',
  Banner = 'Banner',
  Playlist = 'Video Playlist',
}

export enum LayoutCustomizableTypes {
  NonCutomizable = 'non_customizable',
  FullyCustomizable = 'fully_customizable',
  PartiallyCustomizable = 'partially_customizable',
}

export interface HostableLayout {
  id: number;
  name: string;
  accountId: number;
  configurations: HostableLayoutConfiguration[];
  layoutableId: number;
  layoutableType: LayoutableType;
  isCustom: boolean;
  customizable: LayoutCustomizableTypes;
  activeAt: Date;
}

export interface HostableLayoutConfiguration extends WidgetContraints {
  order?: number;
  widgetData: Widget;
}

export interface WidgetContraints {
  columnWidth: number;
  leftCol: number;
  rowStart: number;
  rowHeight: number;
  maxHeight?: number;
  minHeight?: number;
}

export interface Widget {
  id: number;
  name: WidgetName;
  aspectRatio: string;
  customData: WidgetCustomData;
}

export interface WidgetCustomData {
  text?: { value: string };
  image?: number | string;
  imageUrl?: string;
  sendToMail?: { values: string[] };
}

export interface CreateLayoutDTO {
  layoutableId: number;
  layoutableType: LayoutableType;
  name: string;
  configurations: HostableLayoutConfiguration[];
}

export interface UpdateLayoutDTO {
  id: number;
  name: string;
  configurations?: HostableLayoutConfiguration[];
}

export interface SendEmailDto {
  layoutId: number;
  senderFullName: string;
  senderEmail: string;
  message: string;
}

export interface HostableLayoutData {
  layoutId: number;
  layoutName: string;
  isCustom: boolean;
  customizable: LayoutCustomizableTypes;
  constraints: KtdGridLayout;
  widgets: Widget[];
}

export interface HostingVideoAppearance {
  id: number;
  primaryColor: string;
  bigPlayButton: boolean;
  smallPlayButton: boolean;
  author: boolean;
  description: boolean;
  viewCount: boolean;
  volumePanel: boolean;
  progressControl: boolean;
  chaptersButton: boolean;
  captionsButton: boolean;
  pictureInPicture: boolean;
  fullscreen: boolean;
  playbackSpeed: boolean;
  playbackQuality: boolean;
  mutedAutoplay: boolean;
}

export interface ExtensionUploadProgress {
  id: number;
  progress: number;
  status: 'pending' | 'done' | 'failed';
}

export interface AnonymousUserData {
  email?: string;
  fullname?: string;
}

export interface Comment {
  id: number;
  comment: string;
  createdBy: {
    fullname: string;
    id: number;
    designation: string;
    thumbImage: string;
  } | null;
  anonymousUserInfo: AnonymousUserData | null;
  updatedAt: Date;
  createdAt: Date;

  commentableId?: number;
  commentableType?: CommentableType;
  isResolved?: boolean;
  accessibility?: CommentAccessibility;
  timecodeMs?: number; // these are actually seconds not milliseconds
  annotation?: DrawableAreaData;
  permissions?: UserCommentPermissions;
}

export interface CreateInternalCommentDTO {
  commentsToken: string;
  comment: string;
  timecodeMs: number;
  accessibility: CommentAccessibility;
  annotation: DrawableAreaData | null;
}

export interface UpdateInternalCommentDTO {
  comment: string;
  accessibility: CommentAccessibility;
}

export interface ResolveInternalCommentDTO {
  isResolved: boolean;
}

export interface InternalCommentsQueryDTO {
  page: number;
  orderField: CommentableSortOptions;
  includeRelated: boolean;
}

export interface CreatePublicCommentDTO {
  comment: string;
  anonymousUserInfo: AnonymousUserData | null;
}

export interface UpdatePublicCommentDTO {
  comment: string;
}

export interface HubVideoListQueryDto {
  page?: number;
  perPage?: number;
  query?: string;
  isOwner?: boolean | null;
}

export interface HostableFilters {
  hostableTypes?: HostableTypes[];
  userUploadOrigins?: UserUploadOrigin[];
  permissionTypes?: HostablePermissionTypes[];
  embedded?: boolean;
  currentUserAsOwner?: boolean;
  fromAutomation?: boolean;
  tagIds?: number[];
  dateInterval?: DateInterval;
  startDate?: Date | null;
  endDate?: Date | null;
  videoState?: HostableVideoStatuses;
  mustHaveSourceAsset?: boolean;
}
