export enum SessionRecordingState {
  IDLE = 'idle',
  COUNTDOWN = 'countdown',
  RECORDING = 'recording',
}

export enum Platform {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export enum LavaMicType {
  UNKNOWN = 'unknown',
  YES = 'yes',
  NO = 'no',
}

export enum AudioDeviceType {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  BLUETOOTH = 'bluetooth',
}

export enum WhiteBalanceMode {
  AUTO = 'auto',
  LOCKED = 'locked',
  TEMPERATURE = 'temperature',
}

export enum CameraType {
  FRONT = 'front',
  BACK = 'back',
}

export enum Orientation {
  LANDSCAPE = 'landscape',
  PORTRAIT = 'portrait',
}

export enum ThermalState {
  NONE = 'none',
  NOMINAL = 'nominal',
  FAIR = 'fair',
  LIGHT = 'light',
  MODERATE = 'moderate',
  SERIOUS = 'serious',
  SEVERE = 'severe',
  CRITICAL = 'critical',
  EMERGENCY = 'emergency',
  SHUTDOWN = 'shutdown',
}
