export enum TeamJoiningRules {
  InvitationOnly = 'InvitationOnly',
  RequestToJoin = 'RequestToJoin',
  AutoJoin = 'AutoJoin',
}

export enum CAPTURE_ACCESS_LEVEL {  
  Inactive = 'Inactive',
  Director = 'Director',
  Collaborator = 'Collaborator',
}

export enum CREATOR_ACCESS_LEVEL {
  Inactive = 'Inactive',
  Creator = 'Creator',
}

export enum HOSTING_ACCESS_LEVEL {
  Inactive = 'Inactive',
  Director = 'Director',
}

export enum WEBINAR_ACCESS_LEVEL {
  Inactive = 'Inactive',
  Director = 'Director',
}

export interface IUserAssociatedAccount {
  id: number;
  name: string;
  isGeoLocationRestricted?: boolean;
}

export interface IUserDetails {
  loggedin_fullname: string;
  analytics_id?: string;
  urlToken?: string;
  account_id: number;
  email: string;
  user_id: number;
  company_name: string;
  site_user_slug: string;
  image: string;
  is_root_admin: boolean;
  webinarAllowed: boolean;
  hostingAllowed: boolean;
  creatorAllowed: boolean;
  captureAllowed: boolean;
  associated_accounts?: IUserAssociatedAccount[];
  available_accounts?: IUserAssociatedAccount[];
  teamJoiningRule?: TeamJoiningRules;
  defaultCaptureAccessLevel: CAPTURE_ACCESS_LEVEL;
  defaultCreatorAccessLevel: CREATOR_ACCESS_LEVEL;
  defaultHostingAccessLevel: HOSTING_ACCESS_LEVEL;
  defaultWebinarAccessLevel: WEBINAR_ACCESS_LEVEL;
  session_id?: number; // for collab login
  name?: string; // for collab login
  loginId?: number; // for collab login
}

export interface ILiteUserDetails {
  loggedinFullName: string;
  analyticsId?: string;
  urlToken?: string;
  accountId: number;
  email: string;
  id: number;
  name?: string;
  openreelUserId?: number;
  companyName: string;
  siteUserSlug: string;
  image: string;
  isRootAdmin: boolean;
  webinarAllowed: boolean;
  hostingAllowed: boolean;
  creatorAllowed: boolean;
  captureAllowed: boolean;
  associatedAccounts?: IUserAssociatedAccount[];
  availableAccounts?: IUserAssociatedAccount[];
  teamJoiningRule?: TeamJoiningRules;
  defaultCaptureAccessLevel: CAPTURE_ACCESS_LEVEL;
  defaultCreatorAccessLevel: CREATOR_ACCESS_LEVEL;
  defaultHostingAccessLevel: HOSTING_ACCESS_LEVEL;
  defaultWebinarAccessLevel: WEBINAR_ACCESS_LEVEL;
  userCaptureAccessLevel: CAPTURE_ACCESS_LEVEL;
}

export interface CaptureSessionUser {
  source?: 'capture-lite';

  id: number;
  accountId: number;
  sessionAccountId: number;
  name: string;
  sessionCode: string;

  isInternalUser: boolean;
  isOpenreelUser: boolean;

  openreelUserId?: number;
  email?: string;
  userCaptureAccessLevel?: CAPTURE_ACCESS_LEVEL;
}

export interface AutomationUser {
  source: 'automation';
  accountId: number;
  userId: number;
}

export enum AdmissionState {
  Approved = 'approved',
  Pending = 'pending',
  Denied = 'denied',
}

export interface GuestUserAccountInfo {
  first_name: string;
  last_name: string;
  company_name: string;
  password: string;
  token: string;
  account_id?: number;
  available_accounts?: IUserAssociatedAccount[];
  company_website?: string;
  job_type?: string;
  job_title?: string;
  is_social_user?: boolean;
}
