export namespace NumberUtils {
  export function toFixedNum(num: number, fracDigits: number = 2) {
    return parseFloat(num.toFixed(fracDigits));
  }

  export function parseNumber(input, defaultValue): number {
    const num = Number(input);
    return Number.isFinite(num) ? num : defaultValue;
  };
}
